import React, { useState, useEffect, useContext } from "react";
import { login, getOfflineList } from "../services/consts";
import { config } from "../services/installConfig";
import OurHeader from "../components/OurHeader";

function Login(props) {
  const [coords, setCoords] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const defaultSettings = {
    percup: 10,
    markup: 1,
  };

  const [settings, setSettings] = useState(defaultSettings);
  const [error, setError] = useState(null);

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }

  function showPosition(position) {
    setCoords(position.coords);
  }

  const genToken = () => {};

  const bootup = async () => {
    setIsLoading(true);
    const userinfo = await login(
      settings.username,
      settings.password,
      async (user) => {
        if (user !== null && user !== "not_found") {
          setIsLoggedIn(true);
          localStorage["userinfo"] = JSON.stringify(user);
          if (user.admin) {
            window.location.reload();
          } else {
            await getOfflineList(true, null, (data) => {
              localStorage["offlinelist"] = JSON.stringify({
                id: user.pricelist_id,
                items: data,
                date: new Date().toLocaleDateString(),
              });
              window.location.reload();
            });
          }
        } else {
          console.log("User Not Found");
          setIsLoading(false);
          alert("User not found or login incorrect.");
          localStorage.removeItem("userinfo");
          localStorage.removeItem("offlinelist");
          return false;
        }
      }
    );
  };

  const setUsername = (evt) => {
    settings.username = evt.target.value;

    setSettings(settings);
  };

  const setPassword = (evt) => {
    settings.password = evt.target.value;
    setSettings(settings);
  };

  const saveSettings = () => {
    localStorage["userinfo"] = JSON.stringify(settings);
    bootup();
  };

  const loadSettings = () => {
    if (localStorage["userinfo"]) {
      setSettings(JSON.parse(localStorage["userinfo"]));
    }
  };

  const loadUser = () => {
    if (localStorage["userinfo"]) {
      const user = JSON.parse(localStorage["userinfo"]);
      if (localStorage["userinfo"]) {
        const userinfo = login(user.username, user.password, (receivedUser) => {
          if (receivedUser !== null) {
            setIsLoggedIn(true);
          } else {
            return false;
          }
        });
      }
    }
  };

  useEffect(() => {
    loadSettings();
    loadUser();
  }, []);

  if (isLoading) {
    return (
      <div>
        <OurHeader setIsLoading={setIsLoading}></OurHeader>
        <div
          class="container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            padding: "100px",
          }}
        >
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <OurHeader setIsLoading={setIsLoading}></OurHeader>
      <div class="container">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <img
            style={{ maxWidth: "300px", marginTop: 100 }}
            src={"/images/" + config.homeImg}
          />
        </div>

        {!isLoggedIn && (
          <form style={{ marginTop: "40px" }}>
            {error && <h6 style={{ color: "red" }}>{error}</h6>}
            <div class="form-group">
              <label for="exampleInputEmail1">Username</label>
              <input
                type="text"
                onInput={setUsername}
                value={settings.username}
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1">Password</label>
              <input
                type="password"
                value={settings.password}
                onInput={setPassword}
                class="form-control"
              />
            </div>
            <button
              type="button"
              onClick={() => saveSettings()}
              class="btn btn-primary pull-right"
            >
              Sign In
            </button>
          </form>
        )}
      </div>
    </div>
  );
}

export default Login;
