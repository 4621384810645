import React, { useState, useEffect } from "react";
import { login } from "../services/consts";
import { config } from "../services/installConfig";
import { useHistory } from "react-router-dom";

function OurHeader(props) {
  const [user, setUser] = useState({ username: "" });
  const [isAdmin, setIsAdmin] = useState(false);
  const [hasUser, setHasUser] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);
  const history = useHistory();
  const { setIsLoading } = props;

  const loadUser = () => {
    if (localStorage["userinfo"]) {
      const user = JSON.parse(localStorage["userinfo"]);
      if (localStorage["userinfo"]) {
        const userinfo = login(user.username, user.password, (receivedUser) => {
          if (receivedUser !== null) {
            setUser(receivedUser);
            setIsAdmin(receivedUser.admin || false);
            setHasUser(true);

            if (!receivedUser.admin && window.location.pathname === "/") {
              history.push("/search");
            } else if (props.history) {
              if (window.location.pathname === "/") {
                history.push("/manage-users");
              }
            }
            localStorage["userinfo"] = JSON.stringify(receivedUser);
          } else {
            localStorage.removeItem("userinfo");
            return false;
          }
        });
      } else {
        history.push("/");
      }
    } else if (props.history !== undefined) {
      history.push("/");
    }
  };

  const toggleNavbar = () => {
    const toggled = showNavbar;
    setShowNavbar(!toggled);
  };

  useEffect(() => {
    loadUser();
  }, []);

  const logOut = () => {
    localStorage.removeItem("userinfo");
    history.push("/");
    window.location.reload();
  };

  if (!hasUser) {
    return (
      <div>
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
          <a class="navbar-brand" href="/home">
            {config.appName}
          </a>
        </nav>
      </div>
    );
  }

  if (isAdmin) {
    return (
      <div>
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
          <a class="navbar-brand" href="/home">
            {config.appName}
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggleNavbar}
          >
            <span class="navbar-toggler-icon" style={{ color: "#fff" }}>
              toggle
            </span>
          </button>

          <div
            class="collapse navbar-collapse"
            style={{
              display: showNavbar ? "block" : "none",
            }}
            id="navbarSupportedContent"
          >
            <ul class="navbar-nav mr-auto">
              <li>
                <a class="nav-link">Version: {config.version}</a>
              </li>
              <li class="nav-item active">
                <a class="nav-link" href="/">
                  Home
                </a>
              </li>
              {config.stats && (
                <li class="nav-item">
                  <a class="nav-link" href="/stats">
                    Stats
                  </a>
                </li>
              )}

              <li class="nav-item">
                <a class="nav-link" href="/manage-users">
                  Manage Users
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/manage-pricelists">
                  Manage Pricelists
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" onClick={() => logOut()}>
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  } else {
    return (
      <div>
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
          <a class="navbar-brand" href="/home">
            {config.appName}
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggleNavbar}
          >
            <span class="navbar-toggler-icon" style={{ color: "#fff" }}>
              ...
            </span>
          </button>

          <div
            class="navbar-collapse"
            style={{
              display: showNavbar ? "block" : "none",
            }}
            id="navbarSupportedContent"
          >
            <ul class="navbar-nav mr-auto">
              <li class="nav-item">
                <a class="nav-link" href="/search">
                  Search
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/user-settings">
                  Settings
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" onClick={() => logOut()}>
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}
export default OurHeader;
