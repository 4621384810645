import {
  defaultSettings,
  api_base,
  updateUserSearches,
} from "../services/consts.js";

import { config } from "../services/installConfig";

import React, { useState, useEffect } from "react";
import OurHeader from "./OurHeader";
import { getOfflineList, refreshUserInfo, searchNew } from "../services/consts";

function PricelistInfo({ pricelistID }) {
  const [pricelistInfo, setPricelistInfo] = useState([]);

  useEffect(() => {
    console.log("fetching new", pricelistID);
    fetch(api_base + "/pricelists/get", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        pricelist_id: pricelistID,
      }),
    })
      .then((res) => {
        if (res) {
          return res.json();
        }
        return null;
      })
      .then((data) => {
        console.log("GOT DATA", data);
        setPricelistInfo(data[0]);
      });
  }, []);

  if (!pricelistInfo) {
    return (
      <div class="text-center">
        <div style={{ margin: "0 auto" }} class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <p>
      Searching pricelist: {pricelistInfo.name} - Uploaded on:{" "}
      {pricelistInfo.date}
    </p>
  );
}

function Search(props) {
  const [partno, setpartno] = useState("");
  const [searches, setSearches] = useState([]);
  const [user, setUser] = useState(defaultSettings);
  const [showModal, setShowModal] = useState(false);
  const [featured, setFeatured] = useState({});
  const [mode, setMode] = useState("searching");
  const [isAdmin, setIsAdmin] = useState(false);
  const [coords, setCoords] = useState({});
  const [offlineMode, setOfflineMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [gettingOffline, setGettingOffline] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [pricelistID, setPricelistID] = useState(null);

  const parts = [];

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }

  function showPosition(position) {
    setCoords(position.coords);
  }

  const registerSearch = (username, oem, price) => {
    const user = JSON.parse(localStorage["userinfo"]);

    if (user.searches === undefined) {
      user.searches = 1;
    } else {
      user.searches += 1;
    }
    updateUserSearches(user);
    //saveUser(user);
    fetch(api_base + "/searches/put", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        search: {
          username: user.username,
          oem: oem,
          date: new Date(),
          coords: { latitude: coords.latitude, longitude: coords.longitude },
          price: price,
        },
      }),
    })
      .then((res) => {
        if (res) {
          return res.json();
        }
        return null;
      })
      .then((data) => {
        setSearches(data);
      });
  };

  const refreshListID = () => {
    const offlineList = JSON.parse(localStorage["offlinelist"]);
    const PID = JSON.parse(localStorage["userinfo"]).pricelist_id;
    setPricelistID(PID);
  };

  const searchOffline = (val) => {
    console.log("searching offline");
    const parts = JSON.parse(localStorage["offlinelist"]).items;

    refreshListID();

    // console.log("parts", parts);

    const searchedParts = parts.filter((u) =>
      u.number.toLowerCase().includes(val.toLowerCase())
    );

    if (localStorage["usersettings"] !== undefined) {
      const settings = JSON.parse(localStorage["usersettings"]);

      for (let row of searchedParts) {
        if (settings.offsetType === "perc") {
          row.price = (
            parseFloat(row.price) * parseFloat(settings.offset)
          ).toFixed(2);
        } else {
          row.price = (
            parseFloat(row.price) + parseFloat(settings.offset)
          ).toFixed(2);
        }
      }
    }
    setSearches(searchedParts);
  };

  const search = (val) => {
    let userinfo = JSON.parse(localStorage["userinfo"]);
    if (window.navigator.onLine) {
      setLoading(true);
      setGettingOffline(true);
      if (!isFetching) {
        getOfflineList(false, userinfo.pricelist_id, (data) => {
          setIsFetching(false);
          if (data.message && data.message === "pricelist_unchanged") {
            setLoading(false);
            setGettingOffline(false);
          } else {
            localStorage["offlinelist"] = JSON.stringify({
              id: user.pricelist_id,

              items: data,
              date: new Date().toLocaleDateString(),
            });

            refreshListID();
            refreshUserInfo(userinfo.username, userinfo.password, (result) => {
              result && setLoading(false);
              let userinfo = JSON.parse(localStorage["userinfo"]);
              setUser(userinfo);
              setGettingOffline(false);
            });
            window.location.reload();
          }
        });
      }

      setIsFetching(true);
    }

    const searchnumber = val.target.value;
    searchOffline(searchnumber);
    setpartno(searchnumber);
  };

  const showItem = (item) => {
    setFeatured(item);
    registerSearch(user.username, item.number, item.price);
    setMode("details");
  };

  const loadParts = () => {};

  const newSearch = () => {
    window.location.reload();
  };

  const dateToJulianNumber = (d) => {
    // convert a Gregorian Date to a Julian number.
    //    S.Boisseau / BubblingApp.com / 2014
    var x = Math.floor((14 - d.getMonth()) / 12);
    var y = d.getFullYear() + 4800 - x;
    var z = d.getMonth() - 3 + 12 * x;

    var n =
      d.getDate() +
      Math.floor((153 * z + 2) / 5) +
      365 * y +
      Math.floor(y / 4) +
      Math.floor(y / 400) -
      Math.floor(y / 100) -
      32045;

    return n;
  };

  useEffect(() => {
    loadParts();
    getLocation();
    let userinfo = JSON.parse(localStorage["userinfo"]);
    setUser(userinfo);
    console.log("preparing to get offline list");

    // if (localStorage["offlinelist"]) {
    //   const offlinelist = JSON.parse(localStorage["offlinelist"]);

    //   if (offlinelist) {
    //     const lastFetched = dateToJulianNumber(new Date(offlinelist.date));
    //     const now = dateToJulianNumber(new Date());

    //     if (now - lastFetched > 1) {
    //       getOfflineList((data) => {
    //         localStorage["offlinelist"] = JSON.stringify({
    //           id: user.pricelist_id,
    //           items: data,
    //           date: new Date().toLocaleDateString(),
    //         });
    //       });
    //     }
    //   }
    // } else {

    // }
  }, [searches]);

  const renderSearch = () => {
    return (
      <div>
        <OurHeader history={props.history}></OurHeader>
        <div className="container" style={{ marginTop: "40px" }}>
          {/* {JSON.stringify(user)} */}
          {offlineMode && (
            <div
              style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                backgroundColor: "#000",
                padding: "10px",
                left: 0,
              }}
            >
              <p style={{ color: "yellow" }}>
                Server Cannot be Reached: Using Local Search
              </p>
            </div>
          )}
          {user.image && (
            <img
              style={{ maxHeight: "75px", marginBottom: "10px" }}
              src={user.image}
            ></img>
          )}
          {pricelistID && <PricelistInfo pricelistID={pricelistID} />}
          <div class="text-center">
            <h6 style={{ color: "green" }}>Searching Online</h6>
          </div>
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Search Parts"
              aria-label="Search Parts"
              aria-describedby="button-addon2"
              value={partno}
              onInput={(evt) => {
                const searchVal = evt.target.value;
                setLoading(true);
                setpartno(searchVal);
                searchNew(searchVal, (val, parts) => {
                  setSearches(parts);
                  setLoading(false);
                });
              }}
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary"
                type="button"
                id="button-addon2"
              >
                Search
              </button>
            </div>
          </div>
          {gettingOffline ? (
            <>
              <div class="text-center">
                <h3>Checking for a new pricelist</h3>
                <div
                  style={{ margin: "0 auto" }}
                  class="spinner-border"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </>
          ) : loading ? (
            <div class="text-center">
              <div
                style={{ margin: "0 auto" }}
                class="spinner-border"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <div>
              {searches.map((u) => {
                // console.log("searches", u);
                return (
                  <button
                    type="button"
                    class="btn btn-primary btn-lg btn-block"
                    onClick={() => showItem(u)}
                  >
                    {u.number}
                  </button>
                );
              })}{" "}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderDetails = () => {
    return (
      <div>
        <OurHeader history={props.history}></OurHeader>
        <div style={{ marginTop: "40px" }}>
          <div className="container text-center">
            {featured.image1 && featured.image1 !== "\r" && (
              <img
                style={{ maxWidth: "300px" }}
                src={config.imageBucketURL + featured.image1}
              />
            )}
            {featured.image2 && featured.image2 !== "\r" && (
              <img
                style={{ maxWidth: "300px" }}
                src={config.imageBucketURL + featured.image2}
              />
            )}
            {featured.image3 && featured.image3 !== "\r" && (
              <img
                style={{ maxWidth: "300px" }}
                src={config.imageBucketURL + featured.image3}
              />
            )}
            <h3 style={{ textAlign: "center" }}>Part No: {featured.number}</h3>
            <h4 style={{ textAlign: "center" }}>Price: ${featured.price}</h4>

            <button
              type="button"
              class="btn btn-primary btn-lg btn-block"
              onClick={newSearch}
            >
              New Search
            </button>
          </div>
        </div>
      </div>
    );
  };

  if (mode === "searching") {
    return renderSearch();
  } else {
    return renderDetails();
  }
}

export default Search;
